import React from 'react';

const PlayPauseButton = ({ isPlaying, onToggle }) => {
    const iconSrc = isPlaying ? 'Assets/pause.png' : 'Assets/play.png';

    return (
        <div 
            style={{ 
                position: 'absolute', 
                top: '10px', 
                right: '10px', 
                zIndex: 1, 
                backgroundColor: 'rgba(0, 0, 0, 0.5)', 
                padding: '10px', 
                borderRadius: '5px', 
                display: 'flex', 
                alignItems: 'center',
                cursor: 'pointer',
                border: '1px solid white' 
            }} 
            onClick={onToggle}
        >
            <img src={iconSrc} alt={isPlaying ? 'Pause' : 'Play'} style={{ width: '20px', height: '20px', marginRight: '10px' }} />
            <span style={{ color: 'white', fontFamily: 'Arial, sans-serif' }}>{isPlaying ? 'Pause Animations' : 'Play Animations'}</span>
        </div>
    );
};

export default PlayPauseButton;

import React, { useRef } from 'react';
import { useFrame, useThree } from '@react-three/fiber';
import * as THREE from 'three';

const PlanetIndicator = ({ position, color, size = 20, thickness = 1, planetName, selectedPlanet, isOuterPlanet }) => {
    const indicatorRef = useRef();
    const { camera } = useThree();

    useFrame(() => {
        if (indicatorRef.current) {
            indicatorRef.current.quaternion.copy(camera.quaternion); // Make indicator face the camera

            // Hide indicator if the planet is selected
            if (selectedPlanet === planetName) {
                indicatorRef.current.visible = false;
            } else if (!isOuterPlanet) {
                // Hide indicator if the camera is close for inner planets
                const distanceToPlanet = camera.position.distanceTo(new THREE.Vector3(...position));
                indicatorRef.current.visible = distanceToPlanet >= 280; // Adjusted the distance threshold
            } else {
                // Always show for outer planets except if they are selected
                indicatorRef.current.visible = true;
            }
        }
    });

    return (
        <mesh ref={indicatorRef} position={position}>
            <ringGeometry args={[size - thickness, size, 64]} />
            <meshBasicMaterial color={color} side={THREE.DoubleSide} />
        </mesh>
    );
};

export default PlanetIndicator;

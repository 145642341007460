import { useState, useEffect } from 'react';
import * as THREE from 'three';
import { planetsData } from './constants';

const usePlanetPositions = () => {
    const [planetPositions, setPlanetPositions] = useState(planetsData.map(planet => {
        const initialAngleRad = planet.initialAngle;
        const inclinationRad = THREE.MathUtils.degToRad(planet.inclination);
        const position = new THREE.Vector3(
            planet.distance * Math.cos(initialAngleRad),
            0,
            planet.distance * Math.sin(initialAngleRad)
        );

        // Apply inclination transformation
        position.applyAxisAngle(new THREE.Vector3(1, 0, 0), inclinationRad);

        return {
            ...planet,
            position,
            orbitPaused: false,
        };
    }));

    useEffect(() => {
        const updatePositions = () => {
            setPlanetPositions(prevPositions => prevPositions.map(planet => {
                if (planet.orbitPaused) {
                    return planet;
                }
                const angle = ((Date.now() / 1000) % planet.period) / planet.period * 2 * Math.PI;
                const inclinationRad = THREE.MathUtils.degToRad(planet.inclination);
                const newPosition = new THREE.Vector3(
                    planet.distance * Math.cos(angle),
                    0,
                    planet.distance * Math.sin(angle)
                );

                // Apply inclination transformation
                newPosition.applyAxisAngle(new THREE.Vector3(1, 0, 0), inclinationRad);

                return {
                    ...planet,
                    position: newPosition,
                };
            }));
        };

        const interval = setInterval(updatePositions, 16);
        return () => clearInterval(interval);
    }, []);

    return [planetPositions, setPlanetPositions];
};

export default usePlanetPositions;

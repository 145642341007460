import React from 'react';

const HelperSphere = ({ position, color, scale }) => {
    return (
        <mesh position={position} scale={scale}>
            <sphereGeometry args={[1, 32, 32]} />
            <meshBasicMaterial color={color} />
        </mesh>
    );
};

export default HelperSphere;

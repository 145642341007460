import React, { useRef } from 'react';
import { useFrame, useThree } from '@react-three/fiber';
import { Text } from '@react-three/drei';
import * as THREE from 'three';

const DynamicText = ({ children, position, selectedPlanet, isOuterPlanet, onSelect }) => {
    const textRef = useRef();
    const { camera } = useThree();
    const offset = 40;

    useFrame(() => {
        if (textRef.current) {
            const distance = camera.position.distanceTo(new THREE.Vector3(...position));
            let scale;

            if (distance > 10000) {
                scale = isOuterPlanet ? 1000 : 500;
            } else if (distance > 5000) {
                scale = isOuterPlanet ? 500 : 250;
            } else {
                scale = isOuterPlanet ? 200 : 100;
            }

            textRef.current.scale.set(scale, scale, scale);

            // Hide text if the planet is selected
            textRef.current.visible = children !== selectedPlanet;
        }
    });

    return (
        <Text
            ref={textRef}
            position={[position[0], position[1] + offset, position[2]]}
            fontSize={1}
            color="white"
            anchorX="center"
            anchorY="middle"
            onClick={() => onSelect(children)}
        >
            {children}
        </Text>
    );
};

export default DynamicText;

import React, { useState } from 'react';
import { Canvas } from '@react-three/fiber';
import { OrbitControls, Stars } from '@react-three/drei';
import * as THREE from 'three';
import PlanetMenu from './PlanetMenu';
import initialCameraSettings from './InitialCameraSettings';
import { CameraController } from './CameraController';
import Sun from './Sun';
import Planets from './Planets';
import { planetZoomDistances } from './constants';
import usePlanetPositions from './usePlanetPositions';
import HelperSphere from './HelperSphere';
import AsteroidBelt from './AsteroidBelt';
import PlayPauseButton from './PlayPauseButton';
import TitleCard from './TitleCard';
import PlanetInfo from './PlanetInfo';

const SolarSystemScene = () => {
    const [target, setTarget] = useState(initialCameraSettings.target);
    const [zoom, setZoom] = useState(false);
    const [zoomDistance, setZoomDistance] = useState(2000);
    const [selectedPlanet, setSelectedPlanet] = useState('The Sun');
    const [planetPositions, setPlanetPositions] = usePlanetPositions();
    const [isPlaying, setIsPlaying] = useState(true);
    const [showTitleCard, setShowTitleCard] = useState(true);

    const handleSelectPlanet = (planet) => {
        setSelectedPlanet(planet);
        setPlanetPositions(prevPositions => prevPositions.map(p => ({
            ...p,
            orbitPaused: planet !== 'The Sun',
        })));
        if (planet === 'The Sun') {
            setZoomDistance(planetZoomDistances[planet]);
            setTarget(new THREE.Vector3(0, 0, 0));
        } else {
            const selected = planetPositions.find(p => p.name === planet);
            const position = selected.position.toArray();
            setZoomDistance(planetZoomDistances[planet]);
            setTarget(new THREE.Vector3(...position));
        }
        setZoom(true);
    };

    const handleTogglePlayPause = () => {
        setIsPlaying(prev => {
            const newIsPlaying = !prev;
            setPlanetPositions(prevPositions => prevPositions.map(p => ({
                ...p,
                orbitPaused: selectedPlanet === 'The Sun' ? !newIsPlaying : true
            })));
            return newIsPlaying;
        });
    };

    const handleStart = () => {
        setShowTitleCard(false);
    };

    return (
        <div style={{ width: '100vw', height: '100vh', position: 'relative' }}>
            {showTitleCard && <TitleCard onStart={handleStart} />}
            <Canvas
                antialias="true"
                shadows
                camera={{ near: 0.1, far: 500000 }}
                style={{ background: 'black' }}
            >
                <CameraController
                    target={target}
                    zoom={zoom}
                    setZoom={setZoom}
                    zoomDistance={zoomDistance}
                    selectedPlanet={selectedPlanet}
                    planetPositions={planetPositions}
                />
                <Stars radius={100000} depth={20000} count={5000} factor={4} saturation={0} fade speed={1} />
                <OrbitControls
                    minDistance={10}
                    maxDistance={200000}
                    rotateSpeed={-1}
                    maxAzimuthAngle={Infinity}
                    minAzimuthAngle={-Infinity}
                    maxPolarAngle={Math.PI}
                    minPolarAngle={0}
                    enabled={selectedPlanet === 'The Sun'}
                />
                <Sun modelPath={'/Models/Sol/Sol.glb'} scale={[0.03, 0.03, 0.03]} />
                <HelperSphere position={[0, 0, 0]} color="magenta" scale={[5, 5, 5]} />
                <AsteroidBelt modelPath={'/Models/Belt/Belt.glb'} scale={[10, 10, 10]} />
                <directionalLight position={[5, 0, 0]} intensity={1} />
                <directionalLight position={[-5, 0, 0]} intensity={1} />
                <directionalLight position={[0, 5, 0]} intensity={1} />
                <directionalLight position={[0, -5, 0]} intensity={1} />
                <directionalLight position={[0, 0, 5]} intensity={1} />
                <directionalLight position={[0, 0, -5]} intensity={1} />
                <Planets planets={planetPositions} selectedPlanet={selectedPlanet} handleSelectPlanet={handleSelectPlanet} />
            </Canvas>

            {selectedPlanet === 'The Sun' && (
                <PlayPauseButton 
                    isPlaying={isPlaying} 
                    onToggle={handleTogglePlayPause} 
                />
            )}

            <PlanetMenu onSelect={handleSelectPlanet} />
            <PlanetInfo selectedPlanet={selectedPlanet} />
        </div>
    );
};

export default SolarSystemScene;
import React, { useRef, useEffect } from 'react';
import { useLoader } from '@react-three/fiber';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

const AsteroidBelt = ({ modelPath, scale }) => {
    const beltRef = useRef();
    const gltf = useLoader(GLTFLoader, modelPath);

    useEffect(() => {
        if (beltRef.current) {
            gltf.scene.scale.set(...scale);
            beltRef.current.add(gltf.scene);
        }
    }, [gltf, scale]);

    return (
        <group ref={beltRef}>
            <primitive object={gltf.scene} />
        </group>
    );
};

export default AsteroidBelt;

import * as THREE from 'three';

export const sunScaleFactor = 0.03 * 3;
export const sunToEarthRatio = 109;
export const earthScaleFactor = sunScaleFactor / sunToEarthRatio;
export const auToUnits = 2000;

export const planetSizes = {
    Mercury: 0.38,
    Venus: 0.95,
    Earth: 1,
    Mars: 0.53,
    Jupiter: 11.21,
    Saturn: 9.45,
    Uranus: 4.01,
    Neptune: 3.88,
    Pluto: 0.186, // Pluto's size relative to Earth
};

export const planetDistancesAU = {
    Mercury: 0.39,
    Venus: 0.72,
    Earth: 1,
    Mars: 1.52,
    Jupiter: 5.2,
    Saturn: 9.58,
    Uranus: 19.22,
    Neptune: 30.05,
    Pluto: 39.48, // Pluto's distance from the Sun in AU
};

export const jupiterOrbitSeconds = 96; // Adjust this value to slow down the orbits

export const planetsData = [
    { name: 'Mercury', distance: planetDistancesAU.Mercury * auToUnits, color: 'gray', initialAngle: 0, indicatorSize: 10, indicatorThickness: 1, labelYOffset: -10, modelPath: '/Models/Mercury/Mercury.glb', scale: [earthScaleFactor * planetSizes.Mercury, earthScaleFactor * planetSizes.Mercury, earthScaleFactor * planetSizes.Mercury], period: 0.24 / 11.86 * jupiterOrbitSeconds, inclination: 7.0 },
    { name: 'Venus', distance: planetDistancesAU.Venus * auToUnits, color: 'yellow', initialAngle: Math.PI / 4, indicatorSize: 15, indicatorThickness: 1.5, labelYOffset: -10, modelPath: '/Models/Venus/Venus.glb', scale: [earthScaleFactor * planetSizes.Venus, earthScaleFactor * planetSizes.Venus, earthScaleFactor * planetSizes.Venus], period: 0.62 / 11.86 * jupiterOrbitSeconds, inclination: 3.39 },
    { name: 'Earth', distance: planetDistancesAU.Earth * auToUnits, color: '#4B9CD3', initialAngle: Math.PI / 2, width: 6, indicatorSize: 15, indicatorThickness: 1.5, labelYOffset: -10, modelPath: '/Models/Earth/Earth.glb', scale: [earthScaleFactor, earthScaleFactor, earthScaleFactor], period: 1 / 11.86 * jupiterOrbitSeconds, inclination: 0.0 },
    { name: 'Mars', distance: planetDistancesAU.Mars * auToUnits, color: '#FF4500', initialAngle: 3 * Math.PI / 4, indicatorSize: 12, indicatorThickness: 1.2, labelYOffset: -10, modelPath: '/Models/Mars/Mars.glb', scale: [earthScaleFactor * planetSizes.Mars, earthScaleFactor * planetSizes.Mars, earthScaleFactor * planetSizes.Mars], period: 1.88 / 11.86 * jupiterOrbitSeconds, inclination: 1.85 },
    { name: 'Jupiter', distance: planetDistancesAU.Jupiter * auToUnits, color: 'orange', initialAngle: Math.PI, width: 6, indicatorSize: 40, indicatorThickness: 2, labelYOffset: 10, modelPath: '/Models/Jupiter/Jupiter.glb', scale: [earthScaleFactor * planetSizes.Jupiter, earthScaleFactor * planetSizes.Jupiter, earthScaleFactor * planetSizes.Jupiter], period: jupiterOrbitSeconds, inclination: 1.3 },
    { name: 'Saturn', distance: planetDistancesAU.Saturn * auToUnits, color: '#40E0D0', initialAngle: 5 * Math.PI / 4, width: 8, indicatorSize: 36, indicatorThickness: 2, labelYOffset: 10, modelPath: '/Models/Saturn/Saturn.glb', scale: [earthScaleFactor * planetSizes.Saturn, earthScaleFactor * planetSizes.Saturn, earthScaleFactor * planetSizes.Saturn], period: 29.46 / 11.86 * jupiterOrbitSeconds, inclination: 2.48 },
    { name: 'Uranus', distance: planetDistancesAU.Uranus * auToUnits, color: '#98FB98', initialAngle: 3 * Math.PI / 2, indicatorSize: 30, indicatorThickness: 1.5, labelYOffset: 10, modelPath: '/Models/Uranus/Uranus.glb', scale: [earthScaleFactor * planetSizes.Uranus, earthScaleFactor * planetSizes.Uranus, earthScaleFactor * planetSizes.Uranus], period: 84 / 11.86 * jupiterOrbitSeconds, inclination: 0.77 },
    { name: 'Neptune', distance: planetDistancesAU.Neptune * auToUnits, color: '#4169E1', initialAngle: 7 * Math.PI / 4, indicatorSize: 30, indicatorThickness: 1.5, labelYOffset: 10, modelPath: '/Models/Neptune/Neptune.glb', scale: [earthScaleFactor * planetSizes.Neptune, earthScaleFactor * planetSizes.Neptune, earthScaleFactor * planetSizes.Neptune], period: 164.8 / 11.86 * jupiterOrbitSeconds, inclination: 1.77 },
    { name: 'Pluto', distance: planetDistancesAU.Pluto * auToUnits, color: 'purple', initialAngle: 5 * Math.PI / 4, indicatorSize: 30, indicatorThickness: 1.5, labelYOffset: 10, modelPath: '/Models/Pluto/Pluto.glb', scale: [earthScaleFactor * 2.5, earthScaleFactor * 2.5, earthScaleFactor * 2.5], period: 248 / 11.86 * jupiterOrbitSeconds, inclination: 17.16 },
];

export const planetZoomDistances = {
    'The Sun': 125,
    Mercury: 0.35,
    Venus: 1,
    Earth: 1,
    Mars: 0.5,
    Jupiter: 10,
    Saturn: 9.5,
    Uranus: 4,
    Neptune: 4,
    Pluto: 3.5, 
};


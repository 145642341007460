import React, { useRef, useEffect } from 'react';
import { useFrame, useLoader } from '@react-three/fiber';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import HelperBoundingBox from './HelperBoundingBox';

const Planet = ({ modelPath, scale, rotationSpeed }) => {
    const planetRef = useRef();
    const gltf = useLoader(GLTFLoader, modelPath);

    useEffect(() => {
        if (planetRef.current) {
            gltf.scene.scale.set(...scale);
            planetRef.current.add(gltf.scene);
        }
    }, [gltf, scale]);

    useFrame(() => {
        if (planetRef.current) {
            planetRef.current.rotation.y += rotationSpeed; // Adjust rotation speed if needed
        }
    });

    return (
        <group ref={planetRef}>
            <primitive object={gltf.scene} />
            <HelperBoundingBox object={gltf.scene} scale={scale} visible={false} />
        </group>
    );
};

export default Planet;

import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';

const HelperBoundingBox = ({ object, scale = [1, 1, 1], visible = true }) => {
    const boxRef = useRef();

    useEffect(() => {
        if (object && typeof object.traverse === 'function') {
            object.traverse(child => {
                if (child.isMesh) {
                    const box = new THREE.Box3().setFromObject(child);
                    const size = new THREE.Vector3();
                    box.getSize(size);
                    const center = new THREE.Vector3();
                    box.getCenter(center);
                    boxRef.current.scale.set(size.x * scale[0], size.y * scale[1], size.z * scale[2]);
                    boxRef.current.position.copy(center);
                }
            });
        } else {
            console.error("The provided object does not have a traverse method");
        }
    }, [object, scale]);

    return (
        <mesh ref={boxRef} visible={visible}>
            <boxGeometry args={[1, 1, 1]} />
            <meshBasicMaterial color="green" wireframe />
        </mesh>
    );
};

export default HelperBoundingBox;

import React, { useRef, useEffect } from 'react';
import { useLoader } from '@react-three/fiber';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

const Sun = ({ modelPath, scale }) => {
    const sunRef = useRef();
    const gltf = useLoader(GLTFLoader, modelPath);

    useEffect(() => {
        if (sunRef.current) {
            const scaleFactor = scale.map(s => s * 3);
            gltf.scene.scale.set(...scaleFactor);
            sunRef.current.add(gltf.scene);
        }
    }, [gltf, scale]);

    return (
        <group ref={sunRef}>
            <primitive object={gltf.scene} />
        </group>
    );
};

export default Sun;

import React, { useRef, useEffect, useState } from 'react';
import { useFrame, useThree } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import * as THREE from 'three';
import initialCameraSettings from './InitialCameraSettings';
import { easeCubicOut } from 'd3-ease';

export const CameraController = ({ target, zoom, setZoom, zoomDistance, selectedPlanet, planetPositions }) => {
    const { camera, gl } = useThree();
    const controlsRef = useRef();
    const animationProgressRef = useRef(0);
    const startPositionRef = useRef(new THREE.Vector3());
    const startTargetRef = useRef(new THREE.Vector3());
    const previousTargetRef = useRef(initialCameraSettings.target);
    const isInitialLoad = useRef(true);
    const [rotationActive, setRotationActive] = useState(false);
    const rotationProgressRef = useRef(0);
    const rotationSpeed = 0.001; // Adjust the rotation speed as needed

    useEffect(() => {
        if (isInitialLoad.current) {
            // Set the initial camera position and target
            camera.position.copy(initialCameraSettings.position);
            controlsRef.current.target.copy(initialCameraSettings.target);
            controlsRef.current.update();
            isInitialLoad.current = false; // Mark the initial load as done
        } else if (selectedPlanet) {
            // Use current camera position and target as the starting point for the animation
            startPositionRef.current.copy(camera.position);
            startTargetRef.current.copy(controlsRef.current.target);
            previousTargetRef.current.copy(target);
            animationProgressRef.current = 0;
            setRotationActive(false);
            rotationProgressRef.current = 0;
        }
    }, [selectedPlanet, camera, target]);

    useFrame((state, delta) => {
        if (zoom && target) {
            const newPosition = new THREE.Vector3(target.x, target.y + zoomDistance * 0.5, target.z + zoomDistance);
            const startPosition = startPositionRef.current;
            const startTarget = startTargetRef.current;

            // Increment animation progress
            animationProgressRef.current += delta * 0.5; // Adjust this value to control animation speed
            animationProgressRef.current = Math.min(animationProgressRef.current, 1);

            // Use easing function
            const t = easeCubicOut(animationProgressRef.current);

            camera.position.lerpVectors(startPosition, newPosition, t);
            controlsRef.current.target.lerpVectors(startTarget, target, t);
            controlsRef.current.update();

            if (animationProgressRef.current >= 1) {
                setZoom(false);
                setRotationActive(true);
            }
        } else if (rotationActive && selectedPlanet !== 'The Sun') {
            // Slow rotation around the planet to bring the Sun into the background
            const selectedPlanetPosition = planetPositions.find(p => p.name === selectedPlanet).position;
            const sunPosition = new THREE.Vector3(0, 0, 0);
            const directionToSun = new THREE.Vector3().subVectors(sunPosition, selectedPlanetPosition).normalize();
            const offsetPosition = new THREE.Vector3().addVectors(selectedPlanetPosition, directionToSun.multiplyScalar(zoomDistance * -1.5));

            // Calculate the rotation angle around the selected planet
            const angle = rotationProgressRef.current * Math.PI * 2 * rotationSpeed;
            const rotationMatrix = new THREE.Matrix4().makeRotationY(angle);
            const rotatedPosition = new THREE.Vector3().copy(offsetPosition).applyMatrix4(rotationMatrix);

            // Maintain the original viewing angle
            rotatedPosition.y = selectedPlanetPosition.y + zoomDistance * 0.5; // Keep the same y-coordinate for the camera

            camera.position.lerp(rotatedPosition, delta);
            controlsRef.current.target.copy(selectedPlanetPosition);
            controlsRef.current.update();

            // Increment rotation progress
            rotationProgressRef.current += delta * rotationSpeed;
        } else {
            if (selectedPlanet !== 'The Sun') {
                const selectedPlanetPosition = planetPositions.find(p => p.name === selectedPlanet).position;
                const newPosition = new THREE.Vector3(
                    selectedPlanetPosition.x,
                    selectedPlanetPosition.y + zoomDistance * 0.5,
                    selectedPlanetPosition.z + zoomDistance
                );

                camera.position.copy(newPosition);
                controlsRef.current.target.copy(selectedPlanetPosition);
                controlsRef.current.update();
            } else {
                controlsRef.current.update();
            }
        }
    });

    return (
        <OrbitControls
            ref={controlsRef}
            args={[camera, gl.domElement]}
            minDistance={0.1}
            maxDistance={200000}
            enablePan={false}
            enableDamping={true}
            dampingFactor={0.1}
            rotateSpeed={-1}
            maxAzimuthAngle={Infinity}
            minAzimuthAngle={-Infinity}
            maxPolarAngle={Math.PI - 0.1}
            minPolarAngle={0.1}
            enabled={selectedPlanet === 'The Sun'}
        />
    );
};

export default CameraController;

import React, { useMemo } from 'react';
import { Line } from '@react-three/drei';
import * as THREE from 'three';

const OrbitLine = ({ distance, color = 'white', width = 6, inclination = 0 }) => {
    const points = useMemo(() => {
        const curve = new THREE.EllipseCurve(0, 0, distance, distance, 0, 2 * Math.PI);
        return curve.getPoints(1000).map(point => [point.x, 0, point.y]);
    }, [distance]);

    return (
        <group rotation={[THREE.MathUtils.degToRad(inclination), 0, 0]}>
            <Line
                points={points}
                color={color}
                lineWidth={width}
                resolution={4096}
                transparent={true}
                opacity={0.6}
            />
        </group>
    );
};

export default OrbitLine;
import React, { useState } from 'react';

const PlanetMenu = ({ onSelect }) => {
    const planets = [
        { name: 'The Sun', size: 30, color: '#FFD700' },
        { name: 'Mercury', size: 10, color: 'gray' },
        { name: 'Venus', size: 15, color: 'yellow' },
        { name: 'Earth', size: 15, color: '#4B9CD3' },
        { name: 'Mars', size: 12, color: '#FF4500' },
        { name: 'Jupiter', size: 30, color: 'orange' },
        { name: 'Saturn', size: 28, color: '#40E0D0' },
        { name: 'Uranus', size: 20, color: '#98FB98' },
        { name: 'Neptune', size: 20, color: '#4169E1' },
        { name: 'Pluto', size: 10, color: 'purple' }, 
    ];

    const maxSize = 30; 

    return (
        <div style={{ position: 'absolute', bottom: '10px', width: '100%', zIndex: 1, textAlign: 'center' }}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(0, 0, 0, 0.8)', padding: '10px', borderRadius: '5px' }}>
                {planets.map(planet => (
                    <div
                        key={planet.name}
                        style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', margin: '0 10px', padding: '10px', color: 'white', backgroundColor: 'black', border: '1px solid white', borderRadius: '5px', cursor: 'pointer', width: '100px', height: `${maxSize + 30}px`, textAlign: 'center' }}
                        onClick={() => onSelect(planet.name)}
                    >
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: `${maxSize}px` }}>
                            <div style={{ width: `${planet.size}px`, height: `${planet.size}px`, backgroundColor: planet.color, borderRadius: '50%' }}></div>
                        </div>
                        <div>{planet.name}</div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default PlanetMenu;

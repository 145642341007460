import React from 'react';
import Planet from './Planet';
import PlanetIndicator from './PlanetIndicator';
import DynamicText from './DynamicText';
import OrbitLine from './OrbitLine';
import * as THREE from 'three';

const Planets = ({ planets, selectedPlanet, handleSelectPlanet }) => {
    return (
        <>
            {planets.map((planet, index) => {
                const isOuterPlanet = planet.name === 'Jupiter' || planet.name === 'Saturn' || planet.name === 'Uranus' || planet.name === 'Neptune';

                let rotationSpeed = 0.001;
                let tiltAngle = 0;
                if (planet.name === 'Saturn') {
                    rotationSpeed = 0.01; // Increase rotation speed for Saturn
                    tiltAngle = THREE.MathUtils.degToRad(26.73);
                }

                return (
                    <React.Fragment key={index}>
                        <OrbitLine 
                            distance={planet.distance} 
                            color={planet.color} 
                            width={planet.width || 4} 
                            inclination={planet.inclination || 0} 
                        />
                        <mesh position={planet.position.toArray()} rotation={[0, 0, tiltAngle]} castShadow receiveShadow>
                            <Planet modelPath={planet.modelPath} scale={planet.scale} rotationSpeed={rotationSpeed} />
                        </mesh>
                        <group position={planet.position.toArray()}>
                            <PlanetIndicator position={[0, 0, 0]} color={planet.color} size={planet.indicatorSize} thickness={planet.indicatorThickness} planetName={planet.name} selectedPlanet={selectedPlanet} isOuterPlanet={isOuterPlanet} />
                            <DynamicText position={[0, planet.labelYOffset + 40, 0]} selectedPlanet={selectedPlanet} isOuterPlanet={isOuterPlanet} onSelect={handleSelectPlanet}>{planet.name}</DynamicText>
                        </group>
                    </React.Fragment>
                );
            })}
        </>
    );
};

export default Planets;

import React from 'react';
import './PlanetInfo.css';

const planetData = {
  'Mercury': {
    type: 'Terrestrial Planet',
    diameter: '3,032 miles',
    distanceToSun: '35.98 million miles',
    moons: 0,
    elements: ['Oxygen', 'Sodium', 'Hydrogen'],
    description: 'Mercury is the smallest planet in our solar system and closest to the Sun. It has a thin atmosphere and surface temperatures that vary wildly.'
  },
  'Venus': {
    type: 'Terrestrial Planet',
    diameter: '7,520 miles',
    distanceToSun: '67.24 million miles',
    moons: 0,
    elements: ['Carbon Dioxide', 'Nitrogen', 'Sulfuric Acid'],
    description: 'Venus is the second planet from the Sun and has a thick, toxic atmosphere. It is the hottest planet in our solar system.'
  },
  'Earth': {
    type: 'Terrestrial Planet',
    diameter: '7,917.5 miles',
    distanceToSun: '92.96 million miles',
    moons: 1,
    elements: ['Oxygen', 'Silicon', 'Aluminum'],
    description: 'Earth is the third planet from the Sun and the only known planet to support life. It has a diverse climate and environment.'
  },
  'Mars': {
    type: 'Terrestrial Planet',
    diameter: '4,212 miles',
    distanceToSun: '141.6 million miles',
    moons: 2,
    elements: ['Carbon Dioxide', 'Nitrogen', 'Argon'],
    description: 'Mars is the fourth planet from the Sun and is known as the Red Planet. It has the largest volcano and canyon in the solar system.'
  },
  'Jupiter': {
    type: 'Gas Giant',
    diameter: '86,881 miles',
    distanceToSun: '483.8 million miles',
    moons: 79,
    elements: ['Hydrogen', 'Helium', 'Methane'],
    description: 'Jupiter is the largest planet in our solar system and has a Great Red Spot, which is a giant storm. It has a strong magnetic field and dozens of moons.'
  },
  'Saturn': {
    type: 'Gas Giant',
    diameter: '72,367 miles',
    distanceToSun: '890.7 million miles',
    moons: 82,
    elements: ['Hydrogen', 'Helium', 'Methane'],
    description: `Saturn is the gas giant known for its stunning ring system. As of 2019, Saturn holds the record for the most moons in the solar system with a total of 82 moons. Sorry, Jupiter!`
  },
  'Uranus': {
    type: 'Ice Giant',
    diameter: '31,518 miles',
    distanceToSun: '1.784 billion miles',
    moons: 27,
    elements: ['Hydrogen', 'Helium', 'Methane'],
    description: 'Uranus is the seventh planet from the Sun and has a blue-green color due to methane in its atmosphere. It rotates on its side.'
  },
  'Neptune': {
    type: 'Ice Giant',
    diameter: '30,598 miles',
    distanceToSun: '2.793 billion miles',
    moons: 14,
    elements: ['Hydrogen', 'Helium', 'Methane'],
    description: 'Neptune is the eighth planet from the Sun and has the strongest winds in the solar system. It appears blue due to methane in its atmosphere.'
  },
  'Pluto': {
    type: 'Dwarf Planet',
    diameter: '1,476 miles',
    distanceToSun: '3.67 billion miles',
    moons: 5,
    elements: ['Nitrogen', 'Methane', 'Carbon Monoxide'],
    description: 'Pluto is a dwarf planet located in the Kuiper belt. It has a thin atmosphere and five known moons, with Charon being the largest.'
  },
  'The Sun': {
    type: 'Star',
    diameter: '864,340 miles',
    elements: ['Hydrogen', 'Helium'],
    description: `The Sun is the star at the center of our solar system. It provides the energy necessary for life on Earth through nuclear fusion. The Sun is about 864,340 miles in diameter and is composed primarily of hydrogen and helium. The Sun is 109 times larger than the Earth.`
  },
};

const PlanetInfo = ({ selectedPlanet }) => {
  if (!selectedPlanet || !planetData[selectedPlanet]) return null;

  const planet = planetData[selectedPlanet];

  return (
    <div className="planet-info">
      <h2>{selectedPlanet}</h2>
      <hr/>
      <p><strong>Type:</strong> {planet.type}</p>
      <p><strong>Diameter:</strong> {planet.diameter}</p>
      {selectedPlanet !== 'The Sun' && <p><strong>Distance to Sun:</strong> {planet.distanceToSun}</p>}
      {planet.moons > 0 && <p><strong># of Moons:</strong> {planet.moons}</p>}
      <p><strong>Common Elements:</strong> {planet.elements.join(', ')}</p>
      <br/>
      <p>{planet.description}</p>
    </div>
  );
};

export default PlanetInfo;
import React from 'react';
import './TitleCard.css';

const TitleCard = ({ onStart }) => {
    return (
        <div className="title-card">
            <video autoPlay loop muted className="background-video">
                <source src="/Assets/universe.mp4" type="video/mp4" />
            </video>
            <div className="title-content">
                <h1>Welcome to the Solar System!</h1>
                <p>
                    Explore the wonders of our solar system. Select a planet to learn more about it. Navigate between planets using the footer menu.
                </p>
                <button onClick={onStart}>Start</button>
            </div>
        </div>
    );
};

export default TitleCard;